var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        {
          staticClass: "searchWrapper",
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.searchData.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form",
              attrs: {
                "label-position": "right",
                inline: true,
                model: _vm.listQuery,
              },
            },
            [
              _c("div", { staticClass: "search_box_title" }, [
                _vm._v(_vm._s(_vm.$t("searchModule.Query_Table"))),
              ]),
              _c("div", { staticClass: "col_box" }, [
                _c(
                  "div",
                  { staticClass: "col_left" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "展示模块：" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", placeholder: "全部" },
                            on: { change: _vm.getDisplayPositionList },
                            model: {
                              value: _vm.moduleId,
                              callback: function ($$v) {
                                _vm.moduleId = $$v
                              },
                              expression: "moduleId",
                            },
                          },
                          _vm._l(_vm.moduleList, function (item) {
                            return _c("el-option", {
                              key: item.moduleId,
                              attrs: {
                                label: item.terminal + "->" + item.moduleName,
                                value: item.moduleId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "展示位：" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              "collapse-tags": "",
                              multiple: "",
                              placeholder: "全部",
                            },
                            model: {
                              value: _vm.listQuery.moduleIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.listQuery, "moduleIds", $$v)
                              },
                              expression: "listQuery.moduleIds",
                            },
                          },
                          _vm._l(_vm.displayPositionList, function (item) {
                            return _c("el-option", {
                              key: item.moduleId,
                              attrs: {
                                label: item.location,
                                value: item.location,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "区域：" } },
                      [
                        _c("el-cascader", {
                          attrs: {
                            options: _vm.cityList,
                            props: _vm.props,
                            "show-all-levels": false,
                            placeholder: "全部",
                            "collapse-tags": "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.listQuery.areaIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "areaIds", $$v)
                            },
                            expression: "listQuery.areaIds",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "分类：" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              placeholder: "全部",
                              multiple: "",
                              "collapse-tags": "",
                            },
                            model: {
                              value: _vm.listQuery.categoryIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.listQuery, "categoryIds", $$v)
                              },
                              expression: "listQuery.categoryIds",
                            },
                          },
                          _vm._l(_vm.categoryList, function (item) {
                            return _c("el-option", {
                              key: item.categoryId,
                              attrs: {
                                label: item.name,
                                value: item.categoryId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "展示状态：" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              "collapse-tags": "",
                              clearable: "",
                              multiple: "",
                              placeholder: "全部",
                            },
                            model: {
                              value: _vm.listQuery.putStatuses,
                              callback: function ($$v) {
                                _vm.$set(_vm.listQuery, "putStatuses", $$v)
                              },
                              expression: "listQuery.putStatuses",
                            },
                          },
                          [
                            _c(
                              "el-option",
                              { attrs: { label: "已保存", value: 0 } },
                              [_vm._v("已保存")]
                            ),
                            _c(
                              "el-option",
                              { attrs: { label: "待上架", value: 1 } },
                              [_vm._v("待上架")]
                            ),
                            _c(
                              "el-option",
                              { attrs: { label: "已上架", value: 2 } },
                              [_vm._v("已上架")]
                            ),
                            _c(
                              "el-option",
                              { attrs: { label: "已下架", value: 3 } },
                              [_vm._v("已下架")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "展示时间：" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetimerange",
                            "range-separator": "～",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            "default-time": ["00:00:00", "23:59:59"],
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                          },
                          model: {
                            value: _vm.showTimeArray,
                            callback: function ($$v) {
                              _vm.showTimeArray = $$v
                            },
                            expression: "showTimeArray",
                          },
                        }),
                        _c("i", {
                          staticClass: "el-icon-date el-icon-date-custom",
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "最后修改时间：" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetimerange",
                            "range-separator": "~",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            "default-time": ["00:00:00", "23:59:59"],
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                          },
                          model: {
                            value: _vm.updateTimeArray,
                            callback: function ($$v) {
                              _vm.updateTimeArray = $$v
                            },
                            expression: "updateTimeArray",
                          },
                        }),
                        _c("i", {
                          staticClass: "el-icon-date el-icon-date-custom",
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "广告ID：" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入准确信息",
                            clearable: "",
                          },
                          model: {
                            value: _vm.listQuery.advertId,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "advertId", $$v)
                            },
                            expression: "listQuery.advertId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "标题：" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入广告主题标题关键词" },
                          model: {
                            value: _vm.listQuery.title,
                            callback: function ($$v) {
                              _vm.$set(_vm.listQuery, "title", $$v)
                            },
                            expression: "listQuery.title",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col_right" },
                  [
                    _c(
                      "AuthorityComponent",
                      {
                        attrs: {
                          ComponentName: "el-button",
                          permission: ["button.query"],
                          icon: "el-icon-search",
                          type: "primary",
                        },
                        on: {
                          click: function ($event) {
                            _vm.pageNum = 1
                            _vm.searchData()
                          },
                        },
                      },
                      [_vm._v("查询 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "info", icon: "el-icon-delete" },
                        on: { click: _vm.onReset },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.reset")))]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col_box_boder" }),
              _c("div", { staticClass: "col_box h44" }, [
                _c(
                  "div",
                  { staticClass: "col_left" },
                  [
                    _c(
                      "AuthorityComponent",
                      {
                        attrs: {
                          ComponentName: "el-button",
                          permission: ["button.createAdv"],
                          type: "primary",
                          icon: "el-icon-plus",
                        },
                        on: { click: _vm.onAdd },
                      },
                      [_vm._v("创建广告 ")]
                    ),
                    _vm.$route.meta.authority.button.setting
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "info", icon: "el-icon-setting" },
                            on: { click: _vm.addjkSeting },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.set_up")))]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c("div", { staticClass: "col_right mbd4" }),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tableWrapper bgFFF paddngB10" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                "header-cell-class-name": "header-call-style",
                border: "",
                data: _vm.tableData,
              },
              on: { "sort-change": _vm.handleSortChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("searchModule.Advertising_ID"),
                  align: "center",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.advertId))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("searchModule.title"),
                  "header-align": "center",
                  "show-overflow-tooltip": "",
                  "min-width": "150px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "left",
                              "popper-class": "poperStyle",
                              width: "200",
                              trigger: "hover",
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "100%", height: "200px" },
                              attrs: { src: scope.row.imgUrl, alt: "" },
                            }),
                            _c("img", {
                              staticStyle: {
                                width: "30px",
                                height: "30px",
                                "vertical-align": "middle",
                                "margin-left": "30px",
                              },
                              attrs: {
                                slot: "reference",
                                src: scope.row.imgUrl,
                                alt: "",
                              },
                              slot: "reference",
                            }),
                          ]
                        ),
                        _c("span", [_vm._v(_vm._s(scope.row.title))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("searchModule.classification"),
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.categoryId
                          ? _c("span", [_vm._v(_vm._s(scope.row.categoryName))])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("searchModule.region"),
                  align: "center",
                  "show-overflow-tooltip": "",
                  "min-width": "150px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.areaNames))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "跳转链接", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.jumpUrlType === 2
                          ? _c("i", { staticClass: "el-icon-s-operation" })
                          : _vm._e(),
                        scope.row.jumpUrlType === 1 && scope.row.jumpUrl
                          ? _c("i", {
                              staticClass: "el-icon-link link",
                              on: {
                                click: function ($event) {
                                  return _vm.toLink(scope.row.jumpUrl)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("searchModule.Display_module"),
                  prop: "moduleName",
                  align: "center",
                  "min-width": "150px",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.moduleName))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("searchModule.Display_position"),
                  prop: "moduleLocName",
                  align: "center",
                  sortable: "custom",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.moduleLocName))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("searchModule.state"), align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("statusFilter")(scope.row.putStatus))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("searchModule.last_Operator"),
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.updateUserName))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("searchModule.last_Change_time"),
                  "min-width": "120px",
                  align: "center",
                  prop: "updateTime",
                  sortable: "custom",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.updateTime))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "center",
                  align: "center",
                  width: "80",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.$route.meta.authority.button.detail ||
                        (_vm.$route.meta.authority.button.editAdv &&
                          (scope.row.putStatus === 0 ||
                            scope.row.putStatus === 3)) ||
                        (_vm.$route.meta.authority.button.lowerShelf &&
                          (scope.row.putStatus === 1 ||
                            scope.row.putStatus === 2))
                          ? _c(
                              "el-dropdown",
                              {
                                on: {
                                  command: function ($event) {
                                    return _vm.handleCommand($event, scope.row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { padding: "0" },
                                    attrs: { type: "text", size: "small" },
                                  },
                                  [
                                    _vm._v("操作"),
                                    _c("i", {
                                      staticClass: "el-icon-arrow-down",
                                    }),
                                  ]
                                ),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c(
                                      "AuthorityComponent",
                                      {
                                        attrs: {
                                          ComponentName: "el-dropdown-item",
                                          permission: ["button.detail"],
                                          command: "detail",
                                        },
                                      },
                                      [_vm._v("详情 ")]
                                    ),
                                    scope.row.putStatus === 0 ||
                                    scope.row.putStatus === 3
                                      ? _c(
                                          "AuthorityComponent",
                                          {
                                            attrs: {
                                              ComponentName: "el-dropdown-item",
                                              permission: ["button.editAdv"],
                                              command: "edit",
                                            },
                                          },
                                          [_vm._v("编辑 ")]
                                        )
                                      : _vm._e(),
                                    scope.row.putStatus === 1 ||
                                    scope.row.putStatus === 2
                                      ? _c(
                                          "AuthorityComponent",
                                          {
                                            attrs: {
                                              ComponentName: "el-dropdown-item",
                                              permission: ["button.lowerShelf"],
                                              command: "lowerShelf",
                                            },
                                          },
                                          [_vm._v("下架 ")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "pagerWrapper" }, [
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-pagination", {
                  attrs: {
                    "current-page": _vm.pageNum,
                    "page-size": _vm.pageSize,
                    layout: "total, prev, pager, next, jumper",
                    total: _vm.total,
                  },
                  on: { "current-change": _vm.handleCurrentChange },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }